// imports
import { useState, useEffect } from "react";
import AnonymousLayout from "../layout/anonymous-layout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Cookie from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { Http } from "../utility/http";
import { getUserDetails } from "../utility/jwt";
import { JwtPayLoad } from "../shared/jwt.interface";

// interfaces
interface LoginFormInputs {
  email: string;
  password: string;
}

// This component showing the login page
const Login = () => {
  // state and hooks
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();

  // validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("E-mail is required")
      .email("Enter valid e-mail"),
    password: Yup.string().required("Please enter your password"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (loginErrorMessage && (errors.email || errors.password)) {
      setLoginErrorMessage("");
    }
  }, [loginErrorMessage, errors.email, errors.password]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  /**
  Handles the form submission when the user clicks the login button.
  This function performs an API call to authentication the valid user
  @param {LoginFormInputs} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = (data: LoginFormInputs) => {
    setLoading(true);

    if (loginErrorMessage) {
      setLoginErrorMessage("");
    }

    // Make the POST request for authentication user API endpoint
    Http.request("post", `${process.env.REACT_APP_API_URL}auth/login`, data)
      .then((res) => {
        // set the access token inside the cookies
        Cookie.set("token", res.data.accessToken);
        const { user }: JwtPayLoad = getUserDetails();
        if (user?.role === "le_data") {
          navigate("/gis");
        } else {
          // navigate the home page
          navigate("/home");
        }
      })
      .catch((err) => {
        setLoading(false);

        // Set an error message if the API request fails
        setLoginErrorMessage(err.response.data.message);
      });
  };

  return (
    <AnonymousLayout>
      <>
        <span className="mask bg-gradient-dark opacity-6"></span>
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-12 mx-auto">
              <div className="card z-index-0 fadeIn3 fadeInBottom card1">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-gradient-primary-login shadow-primary border-radius-lg py-3 pe-1">
                    <h4 className="text-white font-weight-bolder text-center mt-2 mb-0">
                      Sign in
                    </h4>
                  </div>
                </div>
                {loginErrorMessage && (
                  <span className="mt-2 mb-0 text-center text-danger">
                    {loginErrorMessage}
                  </span>
                )}

                <div className="card-body">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="text-start"
                  >
                    {/* Email */}
                    <div
                      className={`input-group input-group-outline ${
                        errors.email ? "mb-1" : "mb-3"
                      } `}
                    >
                      <input
                        type="email"
                        className="form-control"
                        {...register("email")}
                        placeholder="Email Address"
                        autoComplete="current-email"
                      />
                    </div>
                    <div className="mb-3">
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </div>

                    {/* Password */}
                    <div
                      className={`input-group input-group-outline ${
                        errors.password ? "mb-1" : "mb-3"
                      } `}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        {...register("password")}
                        autoComplete="current-password"
                        placeholder="Password"
                      />
                      <span
                        className="input-group-text eye-icon"
                        onClick={togglePasswordVisibility}
                      >
                        <i
                          className={`"bi bi-eye${showPassword ? "-slash" : ""}`}
                        ></i>
                      </span>
                    </div>
                    <div className="mb-3">
                      {errors.password && (
                        <span className="text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </div>

                    {/* Remember me */}
                    <div className="form-check form-switch d-flex align-items-center mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="rememberMe"
                      />
                      <label className="form-check-label mb-0 ms-2">
                        Remember me
                      </label>
                    </div>

                    {/* Submit button */}
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn bg-gradient-primary-login w-100 my-4 mb-2 btn1"
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Sign in"
                        )}
                      </button>
                    </div>

                    {/* Don't have an account? */}
                    <p className="mt-4 text-sm text-center">
                      Don't have an account?{" "}
                      <Link
                        to="/signup"
                        className="text-primary text-gradient font-weight-bold"
                      >
                        Sign up
                      </Link>
                    </p>

                    {/* Forgot Password */}
                    <p className="mt-3 text-sm text-center">
                      <Link
                        to="/forgot-password"
                        className="text-primary text-gradient"
                      >
                        Forgot Password?
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </AnonymousLayout>
  );
};

export default Login;
