// imports
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  closeRequestToggle,
  editRequestListID,
  editRequestListToggle,
  holdRequestToggle,
  requestDetailsToggle,
  requestId,
  requestList,
  requestListClone,
  requestListToggle,
  requestProposalKmlData,
  requestStatus,
  unholdRequestToggle,
} from "../../state/atom/request";
import {
  addProposalToggle,
  requestProposalToggle,
} from "../../state/atom/proposal";
import { getUserDetails } from "../../utility/jwt";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { checkPermission } from "../../utility/permission";
import { HoldRequest } from "./hold-request";
import { CloseRequest } from "./close-request";
import {
  RequestModifiedStatus,
  RequestModifiedStatusClassMap,
  RequestStatus,
} from "../../shared/request.constant";
import { mapCenter, mapCenterPosition } from "../../state/atom/generic";
import {
  IRequestModifiedStatus,
  Request,
  RequestDetails,
} from "../../shared/request.interface";
import React, { useEffect, useRef, useState } from "react";
import { listHighlighter } from "../../state/atom/listhighlighter";
import { UnholdRequest } from "./unhold-request";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as XLSX from "xlsx";
import fileDownload from "js-file-download";
import { capitalizeString } from "../../shared/generic.constant";
import { Pagination } from "react-bootstrap";
// interfaces
interface Props {
  toggleRequestList: boolean;
  proposal: any;
}

/**
Component displaying a list of land requests.
@param {boolean} toggleRequestList - Indicates whether the request list should be displayed.
@param {any} proposal - Proposal data associated with the land requests.
*/
export const LandRequestList = ({ proposal }: Props) => {
  // state(recoil)
  const [request, setRequestList] = useRecoilState(requestList);
  const [requestClone, setRequestListClone] = useRecoilState(requestListClone);
  const [reqDetailsToggle, setRequestDetailsToggle] =
    useRecoilState(requestDetailsToggle);
  const setMapCenter = useSetRecoilState(mapCenter);
  const [holdReqToggle, setHoldRequestToggle] =
    useRecoilState(holdRequestToggle);
  const [unHoldReqToggle, setUnholdRequestToggle] =
    useRecoilState(unholdRequestToggle);
  const [closeReqToggle, setCloseRequestToggle] =
    useRecoilState(closeRequestToggle);
  const [reqListToggle, setToogleRequestList] =
    useRecoilState(requestListToggle);
  const setProposalDetailsToggle = useSetRecoilState(requestProposalToggle);
  const setAddProposalFromToggle = useSetRecoilState(addProposalToggle);
  const [requestID, setRequestId] = useRecoilState(requestId);
  const setRequestStatus = useSetRecoilState(requestStatus);
  const [listHighLighter, setListHighLighter] = useRecoilState(listHighlighter);
  const setRequestProposalKmlData = useSetRecoilState(requestProposalKmlData);
  const [editReqID, setEditRequestId] = useRecoilState(editRequestListID);
  const [editReqToggle, setEditRequestToggle] = useRecoilState(
    editRequestListToggle
  );

  // Show the request detail
  const setRequestData = (id: string) => {
    setRequestDetailsToggle(true);
    setRequestId(id);
  };

  // Show the proposal detail
  const setProposalData = (id: string) => {
    setToogleRequestList(false);
    setProposalDetailsToggle(true);
    setRequestId(id);
    setRequestDetailsToggle(false);
    setAddProposalFromToggle(false);
  };

  // Show the add new proposal form
  const setAddProposalData = (id: string) => {
    setAddProposalFromToggle(true);
    setRequestDetailsToggle(false);
    setToogleRequestList(false);
    setRequestId(id);
  };

  useEffect(() => {
    if (!reqDetailsToggle) {
      setMapCenter({ zoom: 5, center: mapCenterPosition, isShow: false });
    }
  }, [reqDetailsToggle, setMapCenter]);

  // This line retrieves the user details from the JWT payload using the getUserDetails utility function.
  const { user }: JwtPayLoad = getUserDetails();

  const [selectedRequest, setSelectedRequest] = useState<any>([]);
  const [selectedAllRequest, setAllSelectedRequest] = useState<any>(false);

  const selectAllRequest = (e: any) => {
    if (e.target.checked) {
      const requestIds = request?.data?.landRequest.map(
        (req: RequestDetails) => {
          return req.id;
        }
      );
      setSelectedRequest(requestIds);
      setAllSelectedRequest(true);
    } else {
      setSelectedRequest([]);
      setAllSelectedRequest(false);
    }
  };

  const selectRequest = (e: any) => {
    if (e.target.checked) {
      setSelectedRequest((requestIds: any) => {
        return [...requestIds, e.target.value];
      });
    } else {
      setAllSelectedRequest(false);
      setSelectedRequest((requestIds: any) => {
        const requests = requestIds.filter(
          (reqId: string) => reqId !== e.target.value
        );
        return [...requests];
      });
    }
  };

  const exportRequestToXlsx = () => {
    const requestExportData: any = [];
    let requestObject: any = {};
    selectedRequest.forEach((reqId: string) => {
      const reqDetails: any = request.data.landRequest.find(
        (req: RequestDetails) => req.id === reqId
      );
      requestObject = {};
      requestObject["Request Id"] = reqDetails.requestId;
      requestObject["Request Name"] = reqDetails.requestName;
      requestObject["Business"] = reqDetails.businessUnitName.name;
      requestObject["Project Type"] = reqDetails.projectType || "";
      requestObject["Site Name"] = reqDetails.location;
      requestObject["Latitude"] = reqDetails.locationLat;
      requestObject["Longitude"] = reqDetails.locationLon;
      requestObject["State"] = reqDetails.state.stateName;
      requestObject["District"] = reqDetails.district.districtName;
      requestObject["Taluka"] = reqDetails.city.city;
      requestObject["Village"] = reqDetails.village;
      requestObject["Area"] = reqDetails.area;
      requestObject["Land Type"] = reqDetails.landType;
      requestObject["Budget"] = reqDetails.budget || "";
      requestObject["Proposed By"] = reqDetails.proposedBy;
      requestObject["Purchase Type"] =
        handleExportPropPurchaseType(reqDetails.purchaseType) || "";
      requestObject["Company"] = reqDetails.companyName.name;
      requestObject["Is Options Explored By Business"] =
        reqDetails.optionsExploredByBusiness ? "Yes" : "No";
      requestObject["Options Explored By Business Comment"] =
        reqDetails.optionsExploredByBusinessComment;
      requestObject["Status"] = reqDetails.requestStatus;
      requestObject["Created Date Time"] = reqDetails.createdDateTime;
      requestObject["Close Date"] = reqDetails.closeDate;
      requestObject["Specific Request"] = reqDetails.specificRequest;
      requestObject["LE SPOC"] = reqDetails?.requestAssignToLeSpoc2?.id
        ? `${reqDetails?.requestAssignToLeSpoc2?.firstName} ${reqDetails?.requestAssignToLeSpoc2?.lastName}`
        : "";
      requestExportData.push(requestObject);
    });

    const worksheet = XLSX.utils.json_to_sheet(requestExportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    fileDownload(dataBlob, `Request Report.xlsx`);
  };

  const filterRequest = (e: any) => {
    let filteredRequest: any = requestClone.data.landRequest.filter(
      (req: RequestDetails) => {
        return (
          req.location.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
            -1 ||
          req.requestName
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1 ||
          req.businessUnitName.name
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1 ||
          req.companyName.name
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1
        );
      }
    );
    setRequestList({
      data: {
        landRequest: filteredRequest,
      },
    });
    setCurrentPage(1);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setRequestList({
        data: {
          landRequest: requestClone.data.landRequest,
        },
      });
      setCurrentPage(1);
    }
  };

  const handleExportPropPurchaseType = (value: any) => {
    let modifiedPurchaseType = value;
    if (typeof value === "string") {
      switch (value) {
        case "purchaselease":
          modifiedPurchaseType = "Purchase and Lease";
          break;
        case "ats":
          modifiedPurchaseType = value.toUpperCase();
          break;
        default:
          modifiedPurchaseType = capitalizeString(value);
          break;
      }
    }
    return modifiedPurchaseType;
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const ITEMS_PER_PAGE: number = 10;
  const TOTAL_PAGES = Math.ceil(
    request?.data?.landRequest.length / ITEMS_PER_PAGE
  );
  const VISIBLE_PAGE = 5;
  const startPage = Math.max(1, currentPage - Math.floor(VISIBLE_PAGE / 2));
  const endPage = Math.min(TOTAL_PAGES, startPage + VISIBLE_PAGE - 1);

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div
        style={{ left: "390px" }}
        className={
          "request-list-init land-req-list-position " +
          (!reqListToggle ? "request-list-init--collapsed" : "")
        }
      >
        {/* Panel title */}
        <div
          className="text-right panel-title mb-0"
          style={{
            background: "#375db1",
            justifyContent: "normal",
            alignItems: "baseline",
          }}
        >
          <span
            style={{ color: "white", fontSize: "24px", paddingLeft: "20px" }}
          >
            Existing Request List
            {selectedRequest.length > 0 && (
              <button
                type="button"
                onClick={exportRequestToXlsx}
                className="btn bg-gradient-primary-login btn1"
                style={{ margin: "5px 3px" }}
              >
                Export
              </button>
            )}
          </span>
          <div className="form-group has-search user-search-box">
            <span className="fa fa-search form-control-feedback"></span>

            <input
              type="text"
              className="form-control"
              placeholder="Search"
              ref={inputRef}
              onChange={filterRequest}
            />
            <i
              className="bi bi-x-circle-fill cancle-icon"
              onClick={handleClearSearch}
            ></i>
          </div>

          {request?.data?.landRequest.length > 0 && (
            <div>
              <Pagination className="mx-5">
                {currentPage > 1 && (
                  <>
                    <Pagination.First
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    />
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                  </>
                )}
                {Array.from({ length: endPage - startPage + 1 }).map(
                  (_, index: number) => (
                    <Pagination.Item
                      className="bg-gradient"
                      key={startPage + index}
                      active={startPage + index === currentPage}
                      onClick={() => handlePageChange(startPage + index)}
                    >
                      {startPage + index}
                    </Pagination.Item>
                  )
                )}
                {currentPage !== TOTAL_PAGES && (
                  <>
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === TOTAL_PAGES}
                    />
                    <Pagination.Last
                      onClick={() => handlePageChange(TOTAL_PAGES)}
                      disabled={currentPage === TOTAL_PAGES}
                    />
                  </>
                )}
              </Pagination>
            </div>
          )}

          <span
            className="close-btn"
            id="request-list-close"
            onClick={() => {
              setToogleRequestList(false);
            }}
          >
            &times;
          </span>
        </div>
        <div>
          <div className="table-responsive p-0 dx-table">
            <table className="table align-items-center justify-content-center mb-0 text-white list-table table-responsive">
              {/* Table header */}
              <thead
                style={{ background: "darkslateblue", color: "white" }}
                className="table-head"
              >
                <tr>
                  <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedAllRequest}
                      onChange={(e) => selectAllRequest(e)}
                    />
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                    Site Name
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                    REQUEST Name
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                    ABU SPOC
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                    Company Name
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2 action-button">
                    REQUEST DETAILS
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2"></th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "14px" }}>
                {/* Table rows */}
                {request?.data?.landRequest
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((item: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className={
                          listHighLighter
                            ? requestID === item.id
                              ? "selected-row text-white"
                              : ""
                            : ""
                        }
                      >
                        <td className="td">
                          <input
                            type="checkbox"
                            value={item.id}
                            className="form-check-input"
                            onChange={(e) => selectRequest(e)}
                            checked={
                              selectedRequest.includes(item.id) ? true : false
                            }
                          />
                        </td>
                        <td className="td">
                          <p
                            className="text-sm font-weight-bold mb-0"
                            style={{ display: "flex" }}
                          >
                            <CopyToClipboard text={item.id}>
                              <i
                                className="material-icons text-sm me-2 a-link"
                                title="Copy Request ID"
                              >
                                content_copy
                              </i>
                            </CopyToClipboard>

                            {item.location}
                          </p>
                        </td>
                        <td className="td">
                          <p className="text-sm font-weight-bold mb-0">
                            {item.requestName}
                          </p>
                        </td>
                        <td>
                          <p className="text-sm font-weight-bold mb-0">
                            {`${item.createdBy2.firstName} ${item.createdBy2.lastName}`}
                          </p>
                        </td>
                        <td className="td">
                          <p className="text-sm font-weight-bold mb-0">
                            {item.companyName.name}
                          </p>
                        </td>
                        <td className="action-button">
                          <div className="ms-auto">
                            {/* Request details link */}
                            {checkPermission(
                              user?.role,
                              "request",
                              "can_view"
                            ) && (
                              <span>
                                <Link
                                  to=""
                                  title="Request Details"
                                  className="request-detail-init-button btn btn-link text-white text-gradient  mb-0 box-padding"
                                  onClick={() => {
                                    setRequestData(item.id);
                                    setRequestStatus(item.status);
                                    setListHighLighter(true);
                                    setMapCenter({
                                      zoom: 17,
                                      center: [
                                        item.locationLon,
                                        item.locationLat,
                                      ],
                                      isShow: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons custom-icon text-sm me-2">
                                    request_quote
                                  </i>
                                  <span className="action-label ml-10">
                                    Request
                                  </span>
                                </Link>
                              </span>
                            )}

                            {checkPermission(
                              user?.role,
                              "request",
                              "can_edit"
                            ) && (
                              <span title="Edit Request">
                                <i
                                  style={{ cursor: "pointer" }}
                                  className="material-icons text-sm edit-btn"
                                  onClick={() => {
                                    setEditRequestToggle(true);
                                    setEditRequestId(item.id);
                                  }}
                                >
                                  edit
                                </i>
                              </span>
                            )}

                            {/* Proposal list link */}
                            {checkPermission(
                              user?.role,
                              "proposal",
                              "can_view"
                            ) && (
                              <Link
                                to=""
                                title="Proposals List"
                                className="drawer-eng-button btn btn-link text-white text-gradient  mb-0 box-padding"
                                onClick={() => {
                                  setProposalData(item.id);
                                  setRequestProposalKmlData(item.proposalsData);
                                  if (item.proposalsData.length) {
                                    const kmlData = JSON.parse(
                                      item?.proposalsData[0].kmlData
                                    );
                                    if (kmlData) {
                                      const lon =
                                        kmlData.features[0].geometry.coordinates
                                          .length === 1
                                          ? kmlData.features[0].geometry
                                              .coordinates[0][0][0]
                                          : kmlData.features[0].geometry
                                              .coordinates[0];
                                      const lat =
                                        kmlData.features[0].geometry.coordinates
                                          .length === 1
                                          ? kmlData.features[0].geometry
                                              .coordinates[0][0][1]
                                          : kmlData.features[0].geometry
                                              .coordinates[1];

                                      setMapCenter({
                                        zoom: 10,
                                        center: [lon, lat],
                                        isShow: false,
                                      });
                                    }
                                  }
                                }}
                              >
                                <i className="material-icons custom-icon text-sm me-2">
                                  real_estate_agent
                                </i>
                                <span className="action-label">Proposals</span>
                              </Link>
                            )}

                            {/* Proposal add link */}
                            {checkPermission(
                              user?.role,
                              "proposal",
                              "can_add"
                            ) &&
                              !(
                                item.status === "hold" ||
                                item.status === "close"
                              ) && (
                                <Link
                                  className="drawer-eng-button btn btn-link text-white text-gradient mb-0"
                                  to=""
                                  title="Add Proposals"
                                  onClick={() => setAddProposalData(item.id)}
                                >
                                  <i className="material-icons text-sm">
                                    real_estate_agent
                                  </i>
                                  <span className="action-label">
                                    Add Proposals
                                  </span>
                                </Link>
                              )}

                            {/* Hold request link */}
                            {checkPermission(
                              user?.role,
                              "request",
                              "can_hold"
                            ) &&
                              (item.status === RequestStatus.PENDING ||
                                item.status === RequestStatus.INPROGRESS ||
                                item.status === RequestStatus.RESUME) && (
                                <Link
                                  title="Hold Request"
                                  className="drawer-eng-button btn btn-link text-warning text-gradient  mb-0 box-padding"
                                  to=""
                                  onClick={() => {
                                    setHoldRequestToggle(!holdReqToggle);
                                    setRequestId(item.id);
                                  }}
                                >
                                  <i className="material-icons custom-icon text-sm me-2">
                                    front_hand
                                  </i>
                                  <span className="action-label">Hold</span>
                                </Link>
                              )}

                            {/* UnHold request link */}
                            {checkPermission(
                              user?.role,
                              "request",
                              "can_unhold"
                            ) &&
                              item.status === RequestStatus.HOLD && (
                                <Link
                                  title="Unhold Request"
                                  className="drawer-eng-button btn btn-link text-warning text-gradient  mb-0"
                                  to=""
                                  onClick={() => {
                                    setUnholdRequestToggle(!unHoldReqToggle);
                                    setRequestId(item.id);
                                  }}
                                >
                                  <i className="material-icons text-sm me-2">
                                    back_hand
                                  </i>
                                  <span className="action-label">Unhold</span>
                                </Link>
                              )}

                            {/* Close request link */}
                            {checkPermission(
                              user?.role,
                              "request",
                              "can_close"
                            ) &&
                              (item.status === RequestStatus.PENDING ||
                                item.status === RequestStatus.INPROGRESS ||
                                item.status === RequestStatus.HOLD ||
                                item.status === RequestStatus.RESUME) && (
                                <Link
                                  title="Close Request"
                                  className="btn btn-link text-white text-gradient  mb-0 box-padding"
                                  to=""
                                  onClick={() => {
                                    setCloseRequestToggle(!closeReqToggle);
                                    setRequestId(item.id);
                                  }}
                                >
                                  <i className="material-icons custom-icon text-sm me-2">
                                    task_alt
                                  </i>
                                  <span className="action-label">Close</span>
                                </Link>
                              )}
                          </div>
                        </td>

                        {checkPermission(
                          user?.role,
                          "status",
                          "request_status"
                        ) && (
                          <td>
                            <span
                              className={
                                "text-white pending-request text-gradient px-3 mb-0 " +
                                RequestModifiedStatusClassMap[
                                  item.requestStatus as keyof IRequestModifiedStatus
                                ]
                              }
                            >
                              {
                                RequestModifiedStatus[
                                  item.requestStatus as keyof IRequestModifiedStatus
                                ]
                              }
                            </span>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Hold request conformation component */}
      <HoldRequest></HoldRequest>

      {/* Unhold request conformation component */}
      <UnholdRequest></UnholdRequest>

      {/* Close request conformation component */}
      <CloseRequest></CloseRequest>
    </>
  );
};
function filterRequest(
  e: any,
  HTMLInputElement: { new (): HTMLInputElement; prototype: HTMLInputElement }
) {
  throw new Error("Function not implemented.");
}
