// @ts-nocheck
import { useEffect, useRef, useState, useMemo } from "react";
import { loadModules } from "esri-loader";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  gisFeatureLayerFilter,
  gisSites,
  landDetailsToggle,
  landParcelId,
  landSummary,
  landSummaryToggle,
  siteFilterState,
  updateGisFilter,
} from "../../state/atom/gis";
import { mapCenter } from "../../state/atom/generic";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Http } from "../../utility/http";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { getUserDetails } from "../../utility/jwt";
import { ROLES, ROLESNAME } from "../../shared/role.constant";
import { requestList } from "../../state/atom/request";
import { apiHeaders } from "../../utility/generic";
import axios from "axios";

export interface Props {
  classList?: string;
  mapHeight?: string;
  showExtraButton: boolean;
}

export const GISMap = ({ classList, mapHeight, showExtraButton }: Props) => {
  const [landSummaryData, setLandSummary] = useRecoilState(landSummary);
  const setLandSummaryToggle = useSetRecoilState(landSummaryToggle);
  const landRequest = useRecoilValue(requestList);
  const updateGisFilterTrigger = useRecoilValue(updateGisFilter);
  const sites = useRecoilValue(gisSites);
  const [transparency, setTransparency] = useState(false);
  const [toggleLabel, setToggleLabel] = useState(true);
  const setLandDetailsToggle = useSetRecoilState(landDetailsToggle);
  const setLandParcelId = useSetRecoilState(landParcelId);
  const mapCenterPostion = useRecoilValue(mapCenter);
  const [siteFilter, setSiteFilter] = useRecoilState(siteFilterState);
  const mapRef = useRef(null);
  let [gisFeatureLayerFilterData, setGisFeatureLayerFilter] = useRecoilState(
    gisFeatureLayerFilter
  );
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPdf, setPdfLoading] = useState(false);
  const measurementWidgetRef = useRef(null);
  const [view, setView] = useState(null);
  const filterRef = useRef(null);
  const projectRef = useRef(null);
  const { user }: JwtPayLoad = getUserDetails();

  const createMap = useMemo(
    () => async () => {
      loadModules(
        [
          "esri/Map",
          "esri/views/MapView",
          "esri/layers/FeatureLayer",
          "esri/widgets/Measurement",
          "esri/widgets/BasemapGallery",
          "esri/widgets/Expand",
          "esri/widgets/Legend",
          "esri/layers/WebTileLayer",
          "esri/widgets/BasemapToggle",
          "esri/Basemap",
          "esri/layers/GraphicsLayer",
          "esri/symbols/TextSymbol",
        ],
        { css: true }
      )
        .then(
          ([
            Map,
            MapView,
            FeatureLayer,
            Measurement,
            BasemapGallery,
            Expand,
            Legend,
            WebTileLayer,
            BasemapToggle,
            Basemap,
            GraphicsLayer,
            TextSymbol,
          ]) => {
            const map = new Map({
              basemap: "satellite",
            });

            const view = new MapView({
              container: mapRef.current,
              map: map,
              zoom: 5,
              center: mapCenterPostion.center,
            });
            setView(view);

            /* Start point */
            // Create a GraphicsLayer to hold graphics
            const graphicsLayer = new GraphicsLayer();

            // Add the GraphicsLayer to the map
            map.add(graphicsLayer);

            landRequest.data.landRequest.forEach((marker) => {
              let markerSymbol = {
                type: "picture-marker",
                width: "12px",
                height: "12px",
              };

              if (marker.requestStatus === "pending") {
                markerSymbol.url = "images/pending-marker.png";
              }

              if (marker.requestStatus === "in_progress") {
                markerSymbol.url = "images/in-progress-marker.png";
              }
              if (marker.requestStatus === "acquisition") {
                markerSymbol.url = "images/acquisition-marker.png";
              }

              if (marker.requestStatus === "proposal_assigned") {
                markerSymbol.url = "images/proposal-assigned-marker.png";
              }

              const point = {
                type: "point",
                longitude: marker.locationLon,
                latitude: marker.locationLat,
              };

              var txtSym = new TextSymbol({
                text: marker.location,
                yoffset: 7,
                color: "#fff",
              });

              const markerGraphic = {
                geometry: point,
                symbol: markerSymbol,
                attributes: {
                  type: "picture",
                },
              };

              const textGraphic = {
                geometry: point,
                symbol: txtSym,
                attributes: {
                  type: "text",
                },
                visible: false,
              };

              graphicsLayer.add(textGraphic);
              graphicsLayer.add(markerGraphic);
            });

            view.watch("zoom", (zoomLevel) => {
              graphicsLayer.graphics.forEach((pointGraphic) => {
                if (pointGraphic.attributes.type === "text") {
                  if (zoomLevel > 8) {
                    pointGraphic.visible = true;
                  } else {
                    pointGraphic.visible = false;
                  }
                }
              });
            });
            /* End point */

            const measurement = new Measurement({
              view: view,
              activeTool: null,
            });
            view.ui.add(measurement, "top-right");
            measurementWidgetRef.current = measurement;

            const featureLayer = new FeatureLayer({
              url: process.env.REACT_APP_ARC_GIS_URL,
              outFields: ["*"],
              definitionExpression: "site='not-exist'",
              labelingInfo: [
                {
                  labelPlacement: "below-right",
                  labelExpressionInfo: {
                    expression: "$feature.SurveyNo",
                  },
                  maxScale: 1,
                  minScale: 15000,
                },
              ],
            });
            filterRef.current = featureLayer;

            map.add(featureLayer);

            // Add widget to the bottom right corner of the view

            const districtBoundray = new FeatureLayer({
              url: process.env.REACT_APP_DISTRICT_BOUNDARY,
              outFields: ["*"],
            });
            map.add(districtBoundray);

            const stateBoundray = new FeatureLayer({
              url: process.env.REACT_APP_STATE_BOUNDARY,
              outFields: ["*"],
            });
            map.add(stateBoundray);

            const villageBoundray = new FeatureLayer({
              url: process.env.REACT_APP_VILLAGE_BOUNDARY,
              outFields: ["*"],
            });
            map.add(villageBoundray);

            const projectBoundray = new FeatureLayer({
              url: process.env.REACT_APP_PROJECT_BOUNDARY,
              outFields: ["*"],
              definitionExpression: "SITENAME='not-exist'",
            });
            projectRef.current = projectBoundray;
            map.add(projectBoundray);

            const legend = new Legend({
              view: view,
              layerInfos: [
                {
                  layer: featureLayer,
                  title: "Land Parcel Status",
                },
                
              ],
            });
            view.ui.add(legend, "bottom-right");

            const GoogleStreets = new WebTileLayer({
              urlTemplate:
                "https://mts1.google.com/vt/lyrs=m@186112443&hl=x-local&src=app&x={col}&y={row}&z={level}&s=Galile",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });
            const goglesa = new WebTileLayer({
              urlTemplate:
                "https://mts1.google.com/vt/lyrs=s@186112443&hl=x-local&src=app&x={col}&y={row}&z={level}&s=Galile",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });

            const gogleSatellite = new WebTileLayer({
              urlTemplate:
                "https://mt1.google.com/vt/lyrs=s&hl=en&z={level}&x={col}&y={row}",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });

            const googlesate = new Basemap({
              baseLayers: [goglesa],
              thumbnailUrl: "images/google-hybrid.jpeg",
            });
            new BasemapToggle(
              {
                //titleVisible: false,
                view: view,
                nextBasemap: googlesate,
                label: "Hybrid",
              },
              "goglesat1"
            );

            const googlestrt = new Basemap({
              baseLayers: [GoogleStreets],
              thumbnailUrl: "images/google-street.jpeg",
            });
            new BasemapToggle(
              {
                // titleVisible: false,
                view: view,
                nextBasemap: googlestrt,
                label: "Streets",
              },
              "goglesat2"
            );

            const googleSate = new Basemap({
              baseLayers: [gogleSatellite],
              thumbnailUrl: "images/satellite.jpeg",
            });
            new BasemapToggle(
              {
                // titleVisible: false,
                view: view,
                nextBasemap: googleSate,
                label: "Satellite",
              },
              "goglesat3"
            );

            setIsLoading(false);

            view.on("click", (event) => {
              view.hitTest(event).then((response) => {
                const results = response.results;
                console.log("results", results);
                if (results.length > 0) {
                  //const graphic = results[results.length - 1].graphic;
                  const graphic = results.find((res) =>
                    res.graphic.attributes.hasOwnProperty("Request_Id")
                  );

                  console.log("graphic", graphic);
                  const parcelId = graphic?.graphic?.attributes
                    ? graphic?.graphic?.attributes.id
                    : 0;
                  console.log("parcelId", parcelId);
                  //  const parcelId = 4;
                  //const url = window.location.pathname;
                  if (parcelId) {
                    setLandSummaryToggle(false);
                    setLandParcelId(parcelId);
                    setLandDetailsToggle(true);
                    /* if (url === "/dashboard") {
                      navigate("/gis");
                    } */
                  }
                }
              });
            });
          }
        )
        .catch((error) =>
          console.error("Error loading the Esri modules:", error)
        );
    },
    [
      mapCenterPostion.center,
      setLandDetailsToggle,
      setLandParcelId,
      setLandSummaryToggle,
      landRequest,
    ]
  );

  useEffect(() => {
    createMap();
  }, [createMap]);

  const toggleMeasurementType = (mesaureType) => {
    if (measurementWidgetRef.current) {
      measurementWidgetRef.current.activeTool = mesaureType;
    } else {
      measurementWidgetRef.current.clear();
    }
  };

  useEffect(() => {
    return () => {
      setSiteFilter("");
    };
  }, [setSiteFilter]);

  useEffect(() => {
    if (
      Object.values(gisFeatureLayerFilterData).filter((item) => item !== "")
        .length > 0
    ) {
      let definitionExpression = "";
      let projectBoundrayExpression = "";
      if (user.role === ROLES.abu_spoc) {
        definitionExpression = `AND abu_or_lem_user_id='${user.userId}'`;
      }
      if (user.role === ROLES.le_spoc) {
        definitionExpression = `AND le_spoc_user_id='${user.userId}'`;
      }
      if (user.role === ROLES.le_dh) {
        definitionExpression = `AND le_dh_user_id='${user.userId}'`;
      }

      for (const filter of Object.keys(gisFeatureLayerFilterData)) {
        if (gisFeatureLayerFilterData[filter]) {
          if (typeof gisFeatureLayerFilterData[filter] == "string") {
            definitionExpression += `AND ${filter}='${gisFeatureLayerFilterData[filter]}'`;
          } else {
            definitionExpression += `AND ${filter}=${gisFeatureLayerFilterData[filter]}`;
          }
        }
      }

      if (siteFilter) {
        definitionExpression += ` AND site='${siteFilter}'`;
        projectBoundrayExpression = `SITENAME='${siteFilter}'`;
      }

      if (definitionExpression) {
        definitionExpression = definitionExpression.slice(4);
      }
      console.log("definitionExpression3", definitionExpression);
      if (
        /* request.data.request, */
        gisFeatureLayerFilterData.SurveyNo === "" &&
        gisFeatureLayerFilterData.Village === "" &&
        gisFeatureLayerFilterData.BusinessName === "" &&
        gisFeatureLayerFilterData.CompanyName === "" &&
        gisFeatureLayerFilterData.State === "" &&
        gisFeatureLayerFilterData.Site === "" &&
        gisFeatureLayerFilterData.Request_Id === "" &&
        siteFilter === ""
      ) {
        definitionExpression = "";
      }
      console.log("definitionExpression4", definitionExpression);
      if (view) {
        try {
          if (filterRef.current) {
            if (definitionExpression) {
              filterRef.current.definitionExpression = definitionExpression;
              filterRef.current
                .when(() => {
                  return filterRef.current.queryExtent();
                })
                .then((res) => {
                  if (res.extent) {
                    view.goTo(res.extent).catch(function (error) {
                      console.error(error);
                    });
                  }
                });

              projectRef.current.definitionExpression =
                projectBoundrayExpression;
              /* projectRef.current
                .when(() => {
                  return projectRef.current.queryExtent();
                })
                .then((res) => {
                  if (res.extent) {
                    view.goTo(res.extent).catch(function (error) {
                      console.error(error);
                    });
                  }
                }); */
            } else {
              filterRef.current.definitionExpression = "site='not-exist'";
              projectRef.current.definitionExpression = "SITENAME='not-exist'";
              view.goTo({
                zoom: 5,
                center: mapCenterPostion.center,
              });
            }
          } else {
          }
        } catch (e) {
          console.log("Error", e);
        }
      }
    } else {
      if (view) {
        projectRef.current.definitionExpression = "SITENAME='not-exist'";
        filterRef.current.definitionExpression = "site='not-exist'";
        view.goTo({
          zoom: 5,
          center: mapCenterPostion.center,
        });
      }
    }
  }, [
    siteFilter,
    view,
    gisFeatureLayerFilterData,
    user.userId,
    user.role,
    updateGisFilterTrigger,
  ]);

  const exportKML = () => {
    setLoading(true);
    if (siteFilter) {
      Http.request(
        "get",
        `${process.env.REACT_APP_EXPORT_KML_URL}&Site=${siteFilter}`,
        null
      )
        .then((result) => {
          setLoading(false);
          if (
            process.env.REACT_APP_API_URL.indexOf("developerteam.xyz") === -1
          ) {
            const kmlUrl = result.results[0].value.url;
            window.location.href = kmlUrl;
          } else {
            const kmlUrl =
              "https://media-lams.developerteam.xyz/proposal/0d7c71d69421deda8b15542687569b46.kml";
            window.location.href = kmlUrl;
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (str.length > 1) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return str.charAt(0).toUpperCase();
    }
  };

  const printPDF = () => {
    setPdfLoading(true);
    if (siteFilter) {
      //const site = sites.find((s) => s.value === siteFilter);

      const userName = `${capitalizeFirstLetter(
        user?.firstName
      )} ${capitalizeFirstLetter(user?.lastName)}`;

      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}land-parcel/site/details?site=${siteFilter}`,
        null,
        apiHeaders()
      )
        .then((result1) => {
          Http.request(
            "get",
            `${process.env.REACT_APP_PRINT_URL}&site_name=${siteFilter}&business_unit=${result1?.data?.parcel?.business?.name}&user=${userName}`,
            null
          )
            .then((result) => {
              if (process.env.REACT_APP_API_URL.indexOf("lgeom.com") === -1) {
                const kmlUrl = result.results[0].value.url;
                console.log("kmlUrl1");
                //window.location.href = kmlUrl;
                handleDownload(kmlUrl);
              } else {
                console.log("kmlUrl2");
                const kmlUrl =
                  "https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf";
                //window.location.href = kmlUrl;
                handleDownload(kmlUrl);
              }
            })
            .catch((error) => {
              setPdfLoading(false);
              console.log(error);
            });
        })
        .catch((error) => {});
    }
  };

  const handleDownload = async (path) => {
    try {
      const response = await axios.get(path, {
        responseType: "blob",
      });
      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${siteFilter}.pdf`;
      link.click();
      URL.revokeObjectURL(url);
      setPdfLoading(false);
    } catch (error) {
      console.error("Error fetching PDF:", error);
      setPdfLoading(false);
    }
  };

  const setLabel = () => {
    const tglable = !toggleLabel;
    setToggleLabel(tglable);
    let nameClass = {
      labelPlacement: "below-right",
      labelExpressionInfo: {
        expression: tglable ? "$feature.SurveyNo" : "",
      },
    };
    filterRef.current.labelingInfo = [nameClass];
  };

  const manageRangeSlider = () => {
    var opacitySliderContainer = document.getElementById(
      "opacitySliderContainer"
    );
    var opacitySlider = document.getElementById("opacitySlider");
    var sliderHandle = document.getElementById("sliderHandle");

    opacitySliderContainer.addEventListener("input", function (event) {
      var opacityValue = event.target.value;
      filterRef.current.opacity = opacityValue;
      sliderHandle.style.left = opacityValue * 100 + "%";
    });

    // Initialize slider handle
    sliderHandle.style.left = filterRef.current.opacity * 100 + "%";
  };

  return (
    <>
      <div
        ref={mapRef}
        id="viewDiv"
        style={{ height: mapHeight || "100vh" }}
        className={classList}
      >
        {showExtraButton && (
          <>
            <div className="site-filter">
              <Select
                className="site-filter-box"
                placeholder="Select Site"
                isClearable
                isLoading={isLoading}
                options={sites}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSiteFilter(selectedOption.value);
                    setGisFeatureLayerFilter({ Site: selectedOption.value });
                    Http.request(
                      "post",
                      `${process.env.REACT_APP_API_URL}land-parcel/gis`,
                      {
                        filterDataSet: {
                          sites: [selectedOption.value],
                        },
                      },
                      apiHeaders()
                    )
                      .then((result) => {
                        setLandSummary(result?.data);
                      })
                      .catch((error) => {});
                  } else {
                    setSiteFilter(``);
                    setGisFeatureLayerFilter({
                      ...gisFeatureLayerFilterData,
                      Site: "",
                    });
                    Http.request(
                      "post",
                      `${process.env.REACT_APP_API_URL}land-parcel/gis`,
                      null,
                      apiHeaders()
                    )
                      .then((result) => {
                        setLandSummary(result?.data);
                      })
                      .catch((error) => {});
                  }
                }}
              />
              <button
                className={
                  "btn-grad gis-button " + (siteFilter ? "" : "disabled")
                }
                disabled={siteFilter ? false : true}
                onClick={printPDF}
              >
                {!loadingPdf && (
                  <i className="material-icons opacity-10 pe-1">print</i>
                )}
                {loadingPdf && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="dashboard-icons mt-1">&nbsp;Print</span>
              </button>

              <button
                className={
                  "btn-grad gis-button " + (siteFilter ? "" : "disabled")
                }
                disabled={siteFilter ? false : true}
                onClick={exportKML}
              >
                {!loading && (
                  <i className="material-icons opacity-10 pe-1">
                    file_download
                  </i>
                )}
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="dashboard-icons mt-1">&nbsp;KML/KMZ</span>
              </button>
            </div>

            <div
              id="base-map-container"
              className={"base-map-container "}
            ></div>

            <div className="measurement-area">
              <Select
                className="mesaure-box"
                placeholder="Measure"
                isClearable
                options={[
                  {
                    label: "Distance",
                    value: "distance",
                  },
                  {
                    label: "Area",
                    value: "area",
                  },
                ]}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    toggleMeasurementType(selectedOption.value);
                  } else {
                    toggleMeasurementType(null);
                  }
                }}
              />
            </div>

            <div id="opacitySliderContainer" className="trans-slider">
              <button
                className="btn-grad gis-button"
                onClick={() => setTransparency(!transparency)}
              >
                <span className="gis-label">Transparency</span>
                <span title="Transparency" className="material-icons gis-icon">
                  content_paste_search
                </span>
              </button>
              {transparency && (
                <>
                  <input
                    type="range"
                    onChange={manageRangeSlider}
                    min="0"
                    max="1"
                    step="0.01"
                    defaultValue="1"
                    id="opacitySlider"
                  />
                  <div id="sliderHandle"></div>
                </>
              )}
            </div>

            <button
              className="btn-grad label-button"
              onClick={() => {
                setLabel();
              }}
            >
              <span className="gis-label">Label</span>
              <span title="Label" className="material-icons gis-icon">
                label
              </span>
            </button>

            <div className="gis-map-type">
              <div className="dropdown" style={{ paddingRight: "10px" }}>
                <div className="nav-item dropdown align-items-center pe-2">
                  <Link
                    className="nav-link font-weight-bold d-flex text-white dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <i className="material-icons opacity-10 pe-1">public</i>
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    style={{ minWidth: "90px" }}
                  >
                    <li className="map-type-li">
                      <Link id="goglesat1">
                        <span></span>
                      </Link>
                    </li>
                    <li className="map-type-li">
                      {" "}
                      <Link id="goglesat2">
                        <span></span>
                      </Link>
                    </li>
                    <li className="map-type-li">
                      <Link id="goglesat3">
                        <span></span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
