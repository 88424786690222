// @ts-nocheck
import { useEffect, useMemo, useRef, useState } from "react";
import { MainLayout } from "../layout/main-layout";
import { loadModules } from "esri-loader";
// component showing the home page details
export const Sample = () => {
  const mapRef = useRef(null);
  const [mapView, setMapView] = useState("satellite");
  const mapViewRef = useRef(null);
  const createMap = useMemo(
    () => async () => {
      loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/Graphic",
        "esri/layers/GraphicsLayer",
        "esri/layers/WebTileLayer",
        "esri/Basemap",
        "esri/widgets/BasemapToggle",
        "esri/layers/FeatureLayer",
      ])
        .then(
          ([
            Map,
            MapView,
            Graphic,
            GraphicsLayer,
            WebTileLayer,
            Basemap,
            BasemapToggle,
            FeatureLayer,
          ]) => {
            console.log("Sample loading")
            const map = new Map({
              basemap: mapView,
            });

            const view = new MapView({
              container: mapRef.current,
              map: map,
              zoom: 4,
              center: [72.22, 23.0], // Set the initial center point of the map
            });
            mapViewRef.current = view;
            view.container.style.border = "none";
          }
        )
        .catch((error) => {
          console.error("Error loading ArcGIS modules:", error);
        });
    },
    []
  );
  useEffect(() => {
    createMap();
  }, []);

  return (
    <MainLayout>
      <div ref={mapRef} id="viewDiv" style={{ height: "100vh" }}></div>
    </MainLayout>
  );
};
